import React from 'react';
import { AppRoutes } from 'src/lib/routes';
import Link from 'next/link';
import ImageWithFallback from 'src/components/ImageWithFallback';
import { Image } from 'src/types';
import imageStyle from 'src/styles/modules/components/image.module.scss';
import { StaticImageData } from 'next/image';

type Props = {
  title: string;
  body?: string | JSX.Element;
  url: string;
  urlLabel: string;
  image: Image | StaticImageData | undefined;
};

const Banner: React.FC<Props> = ({ title, body, url, urlLabel, image }) => {
  return (
    <div className={`tw-relative ${imageStyle.heroImgContainer}`}>
      <ImageWithFallback
        record={image && 'webpXlUrl' in image ? image : undefined}
        src={image && 'src' in image ? image : undefined}
        imgField="webpXlUrl"
        alt=""
        fill
        className="tw-static tw-block tw-object-cover tw-w-full tw-h-full"
      />
      <div
        className="tw-w-full tw-h-full tw-absolute tw-top-0 tw-left-0"
        style={{
          background:
            'linear-gradient(180deg, rgba(0, 0, 0, 0.90) -46.27%, rgba(0, 0, 0, 0) 55.33%)',
        }}
      ></div>
      <div className="tw-left-0 tw-absolute tw-z-10 tw-top-20 md:tw-top-1/2 tw-transform md:tw--translate-y-1/2 tw-w-full">
        <div className="container">
          <div className="tw-text-center md:tw-text-left tw-font-semibold tw-leading-none tw-text-5xl md:tw-text-6xl tw-font-styleSans tw-text-white tw-mb-8 tw-max-w-sm">
            {title}
          </div>
          {body}
          <div className="tw-text-center md:tw-text-left">
            <Link legacyBehavior prefetch={false} href={url}>
              <a className="btn btn-primary tw-rounded-4xl tw-py-3 tw-px-8 tw-normal-case">
                {urlLabel}
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
