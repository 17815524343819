import { useState, useRef, useEffect } from 'react';
import { NextSeo, OrganizationJsonLd } from 'next-seo';
import { NextPage } from 'next';
import imgLogo from 'public/images/logo.jpg';
import { AppRoutes, ApiRoutes } from 'src/lib/routes';
import ApiClient from 'src/lib/api_client';
import { Testimonial, Collection, City, Option, Country, SiteReview } from 'src/types';
import WithDefaultJsonLd from 'src/components/WithDefaultJsonLd';
import ImageWithFallback from 'src/components/ImageWithFallback';
import styles from 'src/styles/modules/pages/index.module.scss';
import imagestyles from 'src/styles/modules/components/image.module.scss';
import Hero0Image from 'public/images/home/hero_0.jpg';
import Hero1Image from 'public/images/home/hero_1.jpg';
import Hero2Image from 'public/images/home/hero_2.jpg';
import Hero3Image from 'public/images/home/hero_3.jpg';
import Hero5Image from 'public/images/home/hero_5.jpg';
import Illu1Image from 'public/images/home/illu_1.png';
import Illu2Image from 'public/images/home/illu_2.png';
import Homework1Image from 'public/images/home/homework_1.png';
import Homework2Image from 'public/images/home/homework_2.png';
import Homework3Image from 'public/images/home/homework_3.png';
import OurMissionImage from 'public/images/home/our_mission.jpg';
import { commonCarouselSettings, HEADER_HEIGHT } from 'src/lib/constants';
import Slider from 'react-slick';
import Link from 'next/link';
import classNames from 'classnames';
import useInitialData from 'src/hooks/useInitialData';
import { sortBy } from 'lodash-es';
import useQuery from 'src/hooks/useQuery';
import Loading from 'src/components/Loading';
import useBreakpoints from 'src/hooks/useBreakpoints';
import BrandImageGridV2 from 'src/components/BrandImageGridV2';
import HeaderSearch from 'src/components/HeaderSearch';
import VideoCard from 'src/components/VideoCard';
import videoCardStyle from 'src/styles/modules/components/video_card.module.scss';
import Marquee from 'react-fast-marquee';
import CardShuffle from 'src/components/CardShuffle';
import AccordionFaq from 'src/components/AccordionFaq';
import SliderOrDiv from 'src/components/SliderOrDiv';
import ClientOnlyComponent from 'src/components/ClientOnlyComponent';
import Player from '@vimeo/player';
import { getEleOffset } from 'src/helpers';
import noVideoImg from 'public/images/home/no_video.jpg';
import Banner from 'src/components/Banner';

type HomeProps = {
  siteReview: SiteReview;
  options: Option[];
};

const heroImages = [Hero0Image, Hero1Image, Hero2Image, Hero3Image, Hero5Image];

const orderedCountries = [
  'singapore',
  'indonesia',
  'vietnam',
  'thailand',
  'malaysia',
  'taiwan',
  'sri-lanka',
];

const metaTitle = 'Uncover the Unexpected';
const metaDesc =
  'Seek Sophie is your secret guide to seriously cool experiences, the ones that make you go “Whoa, how did you find this?” We believe in experiences that spark magic, connect you to local cultures, and tread lightly on our planet. You in?';

type ExploreCollection = {
  slugCode: string;
  name: string;
};

const Home: NextPage<HomeProps> = ({ options, siteReview }) => {
  const [currentDestination, setCurrentDestination] = useState<Country>({} as Country);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [player, setPlayer] = useState<Player>();
  const [currentTab, setCurrentTab] = useState('all_cities');
  const initialData = useInitialData();
  const { cities, countries } = initialData;
  const { isScreenMd } = useBreakpoints();

  const sortedCountries = sortBy(countries, [
    (o) => {
      const index = orderedCountries.findIndex((v) => v === o.slug);
      return index < 0 ? 99 : index;
    },
  ]);

  const { data } = useQuery<{
    place: ExploreCollection[];
    list: ExploreCollection[];
    activity: ExploreCollection[];
  }>(ApiRoutes.apiExploreCollectionsRoute({}).toUrl());

  const {
    activity: activityCollections,
    place: placeCollections,
    list: listCollections,
  } = data || {};

  useEffect(() => {
    const autoPlayVideo = () => {
      const videoContainerEle = videoContainerRef.current;
      const currentPos = window.pageYOffset;
      if (videoContainerEle && player) {
        const loadMorePos = getEleOffset(videoContainerEle).top - HEADER_HEIGHT;
        if (
          currentPos > loadMorePos &&
          currentPos <= loadMorePos + videoContainerEle.offsetHeight
        ) {
          player.play();
        } else {
          player.pause();
        }
      }
    };

    document.addEventListener('scroll', autoPlayVideo);

    return () => {
      document.removeEventListener('scroll', autoPlayVideo);
    };
  }, [player]);

  useEffect(() => {
    setCurrentDestination(sortedCountries[0] || {});
  }, [sortedCountries.length]);

  return (
    <>
      <NextSeo
        title={metaTitle}
        description={metaDesc}
        canonical={AppRoutes.rootRoute().toUrl()}
        openGraph={{
          url: AppRoutes.rootRoute().toUrl(),
          title: metaTitle,
          description: metaDesc,
          images: [
            {
              url: imgLogo.src,
              width: 600,
              height: 600,
              alt: metaTitle,
            },
          ],
        }}
      />
      <WithDefaultJsonLd>
        <OrganizationJsonLd
          name={metaTitle}
          description={metaDesc}
          image={imgLogo.src}
          logo={imgLogo.src}
          url={AppRoutes.rootRoute().toUrl()}
          aggregateRating={{
            ratingValue: siteReview.rating,
            reviewCount: siteReview.count,
          }}
          sameAs={[
            'https://www.facebook.com/seeksophie',
            'https://www.instagram.com/seeksophie',
            'https://twitter.com/seeksophie',
          ]}
        ></OrganizationJsonLd>
      </WithDefaultJsonLd>

      <div className="tw-relative">
        <Slider
          {...commonCarouselSettings}
          className="tw-hidden md:tw-block"
          arrows={false}
          autoplay={true}
          dots={false}
          slidesToShow={1}
          slidesToScroll={1}
          fade={true}
          speed={3000}
          infinite={true}
        >
          {heroImages.map((heroImage, index) => (
            <div key={index} className={imagestyles.heroImgContainer}>
              <ImageWithFallback
                src={heroImage}
                fill
                alt=""
                className={`tw-block tw-object-cover tw-w-full tw-h-full`}
              />
            </div>
          ))}
        </Slider>

        <div className="video tw-block md:tw-hidden">
          <div className={`${videoCardStyle.videoContainer} ${styles.heroVideo} tw-mx-auto`}>
            <VideoCard
              vimeoId={899400403}
              unmuteOnPlay={true}
              vimeoConfig={{ autoplay: 1, loop: 1 }}
              hideAllButtons={true}
              noVideoImg={noVideoImg}
            />
          </div>
        </div>

        <div className="tw-bg-black tw-opacity-20 tw-w-full tw-h-full tw-absolute tw-top-0 tw-left-0"></div>

        <div className="tw-absolute tw-z-20 tw-top-1/3 md:tw-top-1/2 tw-transform tw--translate-y-1/2 tw-w-full">
          <div className="container">
            <h1 className="tw-text-center md:tw-text-left tw-font-semibold tw-leading-none tw-text-6xl md:tw-text-8xl tw-font-styleSans tw-text-white tw-mb-8">
              Uncover the
              <br />
              unexpected
            </h1>
            <div className="md:tw-max-w-xl tw-px-8 md:tw-px-0">
              <HeaderSearch
                isMaskHidden={true}
                isHeroSearch={true}
                sameAsDesktop={true}
                placeInputPlaceholder="Ready for something new?"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="tw-bg-brand-yellow tw-py-16 md:tw-py-24" ref={videoContainerRef}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="tw-text-center md:tw-text-left tw-leading-none tw-text-brand-navy tw-font-styleSans tw-font-semibold tw-text-5xl md:tw-text-6xl md:tw-mt-36 md:tw-ml-16">
                Why travel if
                <br />
                we’re going to
                <br />
                return the same?
              </div>

              <div className="tw-mt-16 md:tw-mt-48 md:tw-ml-32">
                <div className="tw-text-center md:tw-text-left">
                  <ImageWithFallback
                    src={Illu1Image}
                    alt=""
                    className="tw-static tw-inline-block tw-w-40 md:tw-w-56 tw-h-auto tw-object-cover tw-mb-8"
                  />
                  <div className="tw-text-brand-navy tw-font-styleSans tw-font-semibold tw-text-3xl md:tw-text-4xl tw-leading-none md:tw-leading-none">
                    We scour obscure
                    <br />
                    corners of Asia for
                    <br />
                    remarkable trips,
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="tw-flex md:tw-flex-col md:tw-items-end">
                <div className="video tw-hidden md:tw-block">
                  <div
                    className={`${videoCardStyle.videoContainer} ${styles.videoCard} tw-mx-auto tw-border-2 tw-border-brand-green`}
                  >
                    <VideoCard vimeoId={899400403} unmuteOnPlay={true} setPlayer={setPlayer} noVideoImg={noVideoImg} />
                  </div>
                </div>
              </div>

              <div className="md:tw-ml-24 tw-mt-16 tw-text-center md:tw-text-left">
                <ImageWithFallback
                  src={Illu2Image}
                  alt=""
                  className="tw-static tw-inline-block tw-w-40 md:tw-w-52 tw-h-auto tw-object-cover tw-mb-8"
                />
                <div className="tw-text-brand-navy tw-font-styleSans tw-font-semibold tw-text-3xl md:tw-text-4xl tw-leading-none md:tw-leading-none">
                  so you can unlock
                  <br />
                  new chapters and
                  <br />
                  adventures in life.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container tw-py-16 md:tw-py-24">
        <div className="tw-font-semibold tw-leading-none tw-text-5xl md:tw-text-6xl tw-font-styleSans tw-text-brand-navy tw-mb-8 md:tw-mb-16 tw-text-center tw-max-w-xs md:tw-max-w-full tw-mx-auto">
          Our experiences,
          <br className="tw-hidden md:tw-inline" /> anything but ordinary
        </div>
        <BrandImageGridV2 options={options} />
      </div>

      <div className="tw-bg-brand-green tw-py-4 tw-font-styleSans tw-font-semibold tw-overflow-hidden tw-text-xl md:tw-text-2xl">
        <Marquee>
          <div>Discover hidden gems</div>
          <div className="tw-mx-2">•</div>
          <div>Find ethical wildlife encounters</div>
          <div className="tw-mx-2">•</div>
          <div>Meet awe-inspiring locals</div>
          <div className="tw-mx-2">•</div>
          <div>Discover hidden gems</div>
          <div className="tw-mx-2">•</div>
          <div>Find ethical wildlife encounters</div>
          <div className="tw-mx-2">•</div>
          <div>Meet awe-inspiring locals</div>
          <div className="tw-mx-2">•</div>
          <div>Discover hidden gems</div>
          <div className="tw-mx-2">•</div>
          <div>Find ethical wildlife encounters</div>
          <div className="tw-mx-2">•</div>
          <div>Meet awe-inspiring locals</div>
          <div className="tw-mx-2">•</div>
          <div>Discover hidden gems</div>
          <div className="tw-mx-2">•</div>
          <div>Find ethical wildlife encounters</div>
          <div className="tw-mx-2">•</div>
          <div>Meet awe-inspiring locals</div>
          <div className="tw-mx-2">•</div>
          <div>Discover hidden gems</div>
          <div className="tw-mx-2">•</div>
          <div>Find ethical wildlife encounters</div>
          <div className="tw-mx-2">•</div>
          <div>Meet awe-inspiring locals</div>
          <div className="tw-mx-2">•</div>
          <div>Discover hidden gems</div>
          <div className="tw-mx-2">•</div>
          <div>Find ethical wildlife encounters</div>
          <div className="tw-mx-2">•</div>
          <div>Meet awe-inspiring locals</div>
          <div className="tw-mx-2">•</div>
          <div>Discover hidden gems</div>
          <div className="tw-mx-2">•</div>
          <div>Find ethical wildlife encounters</div>
          <div className="tw-mx-2">•</div>
          <div>Meet awe-inspiring locals</div>
          <div className="tw-mx-2">•</div>
        </Marquee>
      </div>

      <div className="tw-bg-purple tw-py-16 md:tw-py-24">
        <div className="container">
          <div className="row">
            <div className="col-md-4 md:tw-pt-32">
              <div className="tw-font-semibold tw-mb-8 tw-text-center md:tw-text-left tw-text-xs md:tw-text-sm">
                CHOOSE YOUR DESTINATION
              </div>
              <div className="tw-w-60 tw-mx-auto md:tw-mx-0 md:tw-w-full">
                {sortedCountries.map((country) => (
                  <Link legacyBehavior prefetch={false}                     key={country.id}
                    href={country.slug === 'singapore' ? AppRoutes.cityRoute({ id: country.slug }).toPath() : AppRoutes.countryRoute({ id: country.slug }).toPath()}
                  >
                    <a className="tw-flex tw-items-center tw-mb-4 md:tw-mb-0">
                      <div className="tw-rounded-full tw-border-brand-green tw-border-2 tw-block md:tw-hidden tw-mr-4">
                        <ImageWithFallback
                          record={country.image}
                          imgField="webpSmUrl"
                          alt=""
                          className="tw-static tw-w-16 tw-h-16 tw-rounded-full tw-object-cover"
                          fill
                        />
                      </div>
                      <span
                        className={classNames(
                          'tw-font-semibold tw-font-styleSans tw-text-5xl md:tw-text-6xl hover:tw-text-brand-green hover:tw-underline tw-mb-2 tw-leading-none',
                          {
                            'tw-text-brand-navy md:tw-text-brand-navy': currentDestination.id !== country.id,
                            'tw-text-brand-navy md:tw-text-brand-green': currentDestination.id === country.id,
                          }
                        )}
                        onMouseEnter={() => {
                          setCurrentDestination(country);
                        }}
                      >
                        {country.name}
                      </span>
                    </a>
                  </Link>
                ))}
              </div>
            </div>
            <div className="tw-hidden md:tw-block col-8 tw-pl-20">
              <div className="tw-bg-brand-green tw-max-w-sm tw-rounded-lg tw-p-8 tw-absolute tw-top-0 tw-left-0">
                {currentDestination.description}
              </div>
              <div className="tw-py-16">
                <Link legacyBehavior prefetch={false} href={currentDestination.slug ? AppRoutes.countryRoute({ id: currentDestination.slug }).toPath() : '#'}>
                  <a>
                    <ImageWithFallback
                      record={currentDestination.image}
                      imgField="webpLgUrl"
                      alt=""
                      className={`tw-static tw-block tw-w-full tw-border tw-border-brand-green tw-border-solid tw-rounded-lg tw-object-cover ${styles.countryImg}`}
                      fill
                    />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tw-py-16 md:tw-py-24">
        <div className="container">
          <div className="tw-text-center tw-font-styleSans tw-font-semibold tw-text-5xl md:tw-text-6xl tw-leading-none tw-mb-8">
            We’ve done the
            <br />
            homework
          </div>

          <ClientOnlyComponent>
            <SliderOrDiv
              {...commonCarouselSettings}
              isSlider={!isScreenMd}
              slidesToShow={3}
              className={isScreenMd ? 'row' : 'carousel-homework'}
              dots={true}
              arrows={false}
              fade={true}
            >
              <div className="tw-text-center tw-flex-shrink-0 col-md-4">
                <ImageWithFallback
                  src={Homework1Image}
                  alt=""
                  className="tw-h-60 tw-w-auto tw-static tw-inline-block tw-mb-10 tw-object-cover"
                />
                <div className="tw-font-styleSans tw-font-semibold tw-text-2xl tw-leading-none tw-mb-4">
                  No tourist traps
                </div>
                <div>
                  Skip overrated spots and enjoy unique experiences that are better worth your
                  time.
                </div>
              </div>
              <div className="tw-text-center tw-flex-shrink-0 col-md-4">
                <ImageWithFallback
                  src={Homework2Image}
                  alt=""
                  className="tw-h-60 tw-w-auto tw-static tw-inline-block tw-mb-10 tw-object-cover"
                />
                <div className="tw-font-styleSans tw-font-semibold tw-text-2xl tw-leading-none tw-mb-4">
                  Guides you can trust
                </div>
                <div>
                  Our team gets on the ground to handpick every activity so we only recommend what
                  we love.
                </div>
              </div>
              <div className="tw-text-center tw-flex-shrink-0 col-md-4">
                <ImageWithFallback
                  src={Homework3Image}
                  alt=""
                  className="tw-h-60 tw-w-auto tw-static tw-inline-block tw-mb-10 tw-object-cover"
                />
                <div className="tw-font-styleSans tw-font-semibold tw-text-2xl tw-leading-none tw-mb-4">
                  Earth-friendly travel
                </div>
                <div>
                  We obsess over each experience to make sure they’re good for wildlife & our
                  planet.
                </div>
              </div>
            </SliderOrDiv>
          </ClientOnlyComponent>

          <div className="tw-text-center tw-mt-14 md:tw-mt-8">
            <Link legacyBehavior prefetch={false} href={AppRoutes.aboutRoute().toPath()}>
              <a className="btn btn-primary tw-rounded-4xl tw-py-3 tw-px-8">our story</a>
            </Link>
          </div>
        </div>
      </div>

      <Banner
        title="Travel as a force for good"
        image={OurMissionImage}
        url={AppRoutes.ourMissionRoute().toPath()}
        urlLabel="our mission"
        body={
          <div className="tw-text-white tw-leading-snug tw-mb-8 tw-hidden md:tw-block">
            We hope to create a better world of travel
            <br />
            so that our adventures leave only positive
            <br />
            footprints behind.
          </div>
        }
      />

      <div className="tw-bg-brand-navy tw-py-16 md:tw-py-24">
        <div className="container">
          <div className="tw-font-semibold tw-leading-none tw-text-5xl md:tw-text-6xl tw-font-styleSans tw-text-brand-green tw-mb-8 md:tw-mb-16 tw-text-center tw-max-w-xs md:tw-max-w-full tw-mx-auto">
            Overheard on Instagram
          </div>
          <ClientOnlyComponent>
            <CardShuffle />
          </ClientOnlyComponent>
        </div>
      </div>

      <div className="container tw-py-16 md:tw-py-24">
        <h2 className="tw-font-styleSans tw-font-semibold tw-text-2xl md:tw-text-4xl tw-leading-none tw-mb-8">
          Explore more on Seek Sophie
        </h2>

        <div className="tw-hidden md:tw-block">
          <ul className="nav list-horizontal-scroll list-horizontal-scroll--tab my-4 my-md-32 custom-scrollbar">
            <li className="list-horizontal-scroll__item tw-flex-grow">
              <div
                className={classNames(
                  'list-horizontal-scroll__item-text tw-text-center tw-font-semibold',
                  {
                    'active show': currentTab === 'all_cities',
                  }
                )}
                onClick={() => setCurrentTab('all_cities')}
              >
                All cities
              </div>
            </li>
            <li className="list-horizontal-scroll__item tw-flex-grow">
              <div
                className={classNames(
                  'list-horizontal-scroll__item-text tw-text-center tw-font-semibold',
                  {
                    'active show': currentTab === 'activity',
                  }
                )}
                onClick={() => setCurrentTab('activity')}
              >
                Top activities
              </div>
            </li>
            <li className="list-horizontal-scroll__item tw-flex-grow">
              <div
                className={classNames(
                  'list-horizontal-scroll__item-text tw-text-center tw-font-semibold',
                  {
                    'active show': currentTab === 'place',
                  }
                )}
                onClick={() => setCurrentTab('place')}
              >
                Places of interest
              </div>
            </li>
            <li className="list-horizontal-scroll__item tw-flex-grow">
              <div
                className={classNames(
                  'list-horizontal-scroll__item-text tw-text-center tw-font-semibold',
                  {
                    'active show': currentTab === 'list',
                  }
                )}
                onClick={() => setCurrentTab('list')}
              >
                Top collections
              </div>
            </li>
          </ul>

          <div className="tab-content">
            <div
              className={classNames('tab-pane fade', {
                'active show': currentTab === 'all_cities',
              })}
            >
              {sortBy(cities, [(o) => -o.optionsCount]).map((city, index) => (
                <span key={city.id} className="tw-text-grey-light">
                  {index > 0 ? ' | ' : ''}
                  <Link legacyBehavior prefetch={false} href={AppRoutes.cityRoute({ id: city.slug }).toPath()}>
                    <a className="tw-text-grey-light tw-text-sm hover:tw-underline">
                      Things to Do in {city.name}
                    </a>
                  </Link>
                </span>
              ))}
            </div>
            <div
              className={classNames('tab-pane fade', {
                'active show': currentTab === 'activity',
              })}
            >
              {typeof activityCollections === 'undefined' ? (
                <Loading />
              ) : activityCollections.length > 0 ? (
                activityCollections.map((obj, index) => (
                  <span className="tw-text-grey-light" key={obj.slugCode}>
                    {index > 0 ? ' | ' : ''}
                    <Link legacyBehavior prefetch={false} href={AppRoutes.collectionRoute({ id: obj.slugCode }).toPath()}>
                      <a className="tw-text-grey-light tw-text-sm hover:tw-underline">{obj.name}</a>
                    </Link>
                  </span>
                ))
              ) : (
                <div>No activities found</div>
              )}
            </div>
            <div
              className={classNames('tab-pane fade', {
                'active show': currentTab === 'place',
              })}
            >
              {typeof placeCollections === 'undefined' ? (
                <Loading />
              ) : placeCollections.length > 0 ? (
                placeCollections.map((obj, index) => (
                  <span className="tw-text-grey-light" key={obj.slugCode}>
                    {index > 0 ? ' | ' : ''}
                    <Link legacyBehavior prefetch={false} href={AppRoutes.collectionRoute({ id: obj.slugCode }).toPath()}>
                      <a className="tw-text-grey-light tw-text-sm hover:tw-underline">{obj.name}</a>
                    </Link>
                  </span>
                ))
              ) : (
                <div>No places found</div>
              )}
            </div>
            <div
              className={classNames('tab-pane fade', {
                'active show': currentTab === 'list',
              })}
            >
              {typeof listCollections === 'undefined' ? (
                <Loading />
              ) : listCollections.length > 0 ? (
                listCollections.map((obj, index) => (
                  <span className="tw-text-grey-light" key={obj.slugCode}>
                    {index > 0 ? ' | ' : ''}
                    <Link legacyBehavior prefetch={false} href={AppRoutes.collectionRoute({ id: obj.slugCode }).toPath()}>
                      <a className="tw-text-grey-light tw-text-sm hover:tw-underline">{obj.name}</a>
                    </Link>
                  </span>
                ))
              ) : (
                <div>No collections found</div>
              )}
            </div>
          </div>
        </div>
        <div className="tw-block md:tw-hidden">
          <AccordionFaq
            title="All cities"
            renderTitle={() => (
              <span className="tw-font-semibold tw-font-styleSans tw-text-xl">All cities</span>
            )}
            keyName="city"
          >
            <>
              {sortBy(cities, [(o) => -o.optionsCount]).map((city, index) => (
                <span key={city.id} className="tw-text-grey-light">
                  {index > 0 ? ' | ' : ''}
                  <Link legacyBehavior prefetch={false} href={AppRoutes.cityRoute({ id: city.slug }).toPath()}>
                    <a className="tw-text-grey-light tw-text-sm hover:tw-underline">
                      Things to Do in {city.name}
                    </a>
                  </Link>
                </span>
              ))}
            </>
          </AccordionFaq>
          <AccordionFaq
            title="Top activities"
            renderTitle={() => (
              <span className="tw-font-semibold tw-font-styleSans tw-text-xl">Top activities</span>
            )}
            keyName="activity"
          >
            {typeof activityCollections === 'undefined' ? (
              <Loading />
            ) : activityCollections.length > 0 ? (
              activityCollections.map((obj, index) => (
                <span className="tw-text-grey-light" key={obj.slugCode}>
                  {index > 0 ? ' | ' : ''}
                  <Link legacyBehavior prefetch={false} href={AppRoutes.collectionRoute({ id: obj.slugCode }).toPath()}>
                    <a className="tw-text-grey-light tw-text-sm hover:tw-underline">{obj.name}</a>
                  </Link>
                </span>
              ))
            ) : (
              <div>No activities found</div>
            )}
          </AccordionFaq>
          <AccordionFaq
            title="Places of interest"
            renderTitle={() => (
              <span className="tw-font-semibold tw-font-styleSans tw-text-xl">
                Places of interest
              </span>
            )}
            keyName="place"
          >
            {typeof placeCollections === 'undefined' ? (
              <Loading />
            ) : placeCollections.length > 0 ? (
              placeCollections.map((obj, index) => (
                <span className="tw-text-grey-light" key={obj.slugCode}>
                  {index > 0 ? ' | ' : ''}
                  <Link legacyBehavior prefetch={false} href={AppRoutes.collectionRoute({ id: obj.slugCode }).toPath()}>
                    <a className="tw-text-grey-light tw-text-sm hover:tw-underline">{obj.name}</a>
                  </Link>
                </span>
              ))
            ) : (
              <div>No places found</div>
            )}
          </AccordionFaq>
          <AccordionFaq
            title="Top collections"
            renderTitle={() => (
              <span className="tw-font-semibold tw-font-styleSans tw-text-xl">Top collections</span>
            )}
            keyName="top_collections"
          >
            {typeof listCollections === 'undefined' ? (
              <Loading />
            ) : listCollections.length > 0 ? (
              listCollections.map((obj, index) => (
                <span className="tw-text-grey-light" key={obj.slugCode}>
                  {index > 0 ? ' | ' : ''}
                  <Link legacyBehavior prefetch={false} href={AppRoutes.collectionRoute({ id: obj.slugCode }).toPath()}>
                    <a className="tw-text-grey-light tw-text-sm hover:tw-underline">{obj.name}</a>
                  </Link>
                </span>
              ))
            ) : (
              <div>No collections found</div>
            )}
          </AccordionFaq>
        </div>
      </div>
    </>
  );
};

export async function getStaticProps() {
  const [data] = await ApiClient.getHomePage();

  if ('error' in data) {
    return {
      notFound: false,
    };
  }

  const [siteReview] = await ApiClient.getSiteReviews();

  return {
    props: {
      siteReview,
      options: data.options,
    },
    revalidate: 300,
  };
}

export default Home;
